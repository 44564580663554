.bannerOCont {
  background-color: rgb(220, 235, 241);
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding: 1.5rem;
  max-width: 730px;
  /* height: fit-content; */
}

.bannerInner {
  padding: 1.5rem;
  margin-left: 0px;
  background-color: white;
  /* height: calc(810px - 128px); */
}

.bannerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 15px;
}

.nprBanner {
  /* width: 100px;
  height: 390px; */
  /* width: fit-content;
  height: fit-content; */
 
}

.nprIframe {
  width: 260px;
  height: 300px;
}

.nprIframe2{
  width: 300px;
  height: 260px;
  margin: 0px !important;
  padding: 0px !important;
  /* height: 400px; */
}

.nprIframe2Border{
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  width: 300px;
  height: 360px;
  border: solid #ebebeb 1px;
  background-color: white;
}
.publicImpact {
  font-size: 20px !important;
  color: var(--darkRed);
  text-transform: uppercase;
}
.natureTitle {
  font-size: 1.5rem !important;
}

.nytImg {
  /* width: 300px;
  height: 200px; */
}
.nytTimes {
  font-size: 1.1rem !important;
  line-height: 1.8rem !important;
  font-weight: 700 !important;
}
.nytName {
  
  /* color: white; */
  /* text-align: center; */
}

.nyt {
  background-color: black;
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.imgCar{
  /* height: 180px; */
  object-fit: cover;
}

.nytAuthor {
  color: #666;
  /* color: var(--darkBlueLogo); */
  font-size: 12px !important;
  font-weight: 600;
  width: fit-content;
}
.nytDate {
  font-size: 12px;
  font-weight: 400;
  /* color: var(--darkBlueLogo); */
  color: #666;
  width: fit-content;
}
.nytSummary {
  color: #666;
  font-size: 1rem !important;
}

.nytBorder {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  width: 300px;
  height: 360px;
  border: solid #ebebeb 1px;
  background-color: white;
  
}

.imgDesCar{
  padding: 10px 5px 5px 5px;
}
.imgDesCar2{
  padding: 0px 5px 5px 5px;
}
.nytCitation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.nytReadmore {
  display: flex;
  flex-direction: row;
  gap: 3px;
  color: black;
  font-family: "Engravers Old English";
  justify-content: center;
  margin-right: 5px;
}

.nytCol {
  /* width: fit-content; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.impactVid {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
}

.impactVid1 {
  width: 260px;
  height: 200px;
}
.impactVid2 {
  width: 260px;
  height: 200px;
}

.natureArticle {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  border: solid #ebebeb 1px;
}

.natureCitation{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px !important;
  padding: 0px !important;
}

.nytTag{
  font-size: .9rem !important;
  font-weight: 200;
  /* color: var(--darkBlueLogo); */

  /* color: #666; */
}

.nprDesc{
  margin-top: -13px !important;
}
.hrnpr2{
  margin-top: -10px;
}

@media screen and (max-width: 992px){
 .bannerRow{
  justify-content: space-evenly;

 }

}

@media screen and (max-width: 768px){
  .impactVid{
   align-items: center;
  }
}