@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;700&display=swap');


.cuttingEdgeWrapper {
  height: 350px;
  position:relative;
  display: flex;
  flex-direction: row;
  background-color:#f5f5f5; /* Background color behind the image */
  background-image: url('/public/images/home_cover1.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.tools-col-end{
  box-sizing: border-box;
    width: 50%;
    height: fit-content;
    overflow: hidden;
   
}

/* .torso-img {
  display: block;
  height: 100%;
  width: 40%;
  margin-left: auto;
  position: relative; /* Ensures the image is above the pseudo-element */


.cuttingEdgeCont {
  width: 460px;
  height: 226px;
  display: flex;
  flex-direction: column;

  /* justify-content: center; */
  align-self: center;
  /* align-items: center; */
  margin-left: 4rem;
  z-index: 2;
  background-color: white;
  /* border-radius: 10px; */
  padding: 7px;
  
  /* border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px); */
}

.cuttingEdge {
  /* font-size: 3rem; */
  font-weight: 700;
  line-height: 50px;
  /* margin-bottom: 20px; */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* align-self: center; */
  color: black !important;
  padding: 16px;
  /* margin-left: -3rem; */
  /* font-size: 3rem; */

  /* text-shadow: 0 2px 0 navy; */
  /* text-shadow: 0 2px 0 navy; */
}

.cuttingEdge3D {
  box-shadow: inset 5px 5px 12px #e0e0e0, inset -5px -5px 12px #fcfcfc;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  /* justify-content: center;
  align-self: center;
  align-items: center; */
}

.cuttingEdgeButton {
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding-left: 16px;
}

.overflow_column{
  overflow: hidden;
}
.home {
  background-color: #f5f5f5;
  
}

.about {
  padding: 0rem 4rem 4rem 4rem;
}
.sec1 {
  padding-top: 4rem;
  font-size: large;
}

.sec2 {
  padding-top: 3rem;
}
.publicSec{
  padding: 4rem 4rem 4rem 4rem;
  /* background-color: var(--darkBlue); */
}
.bgPic {
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -2; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* background-color: white !important; */
}

.hexagon1 {
  width: 0;
  border-bottom: 30px solid #6c6;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
}
.hexagon2 {
  width: 104px;
  height: 60px;
  background-color: #6c6;
}
.hexagon3 {
  width: 0;
  border-top: 30px solid #6c6;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
}
.headerMolec{
display: flex;
flex-direction: column;
margin-bottom: 2rem !important;
padding: 0px !important;
}

.headerMolec h3 .minkara{
  font-family: 'Work Sans', sans-serif;
  font-size: 40px;
  font-weight: 300;
  margin: 0px !important;
  padding: 0px !important;
}
.headerMolec h3 {
  margin: 0px !important;
  padding: 0px !important;
}

.combine{
  font-weight: 600;
  font-size: 40px !important;
  margin: 0px !important;
  padding: 0px !important;
}
.molec{
  font-size: 21px !important;
  padding-bottom: 20px;
  font-style: italic;
  font-weight:500;
  text-transform: uppercase;
  font-family: 'PT Serif', serif;
  margin: 0px;
}
.banner{
  margin: 0px;
  padding: 0px;
  max-width: 1000px;
}

.homeVidStyling{
  width: 100%;
}

.homeVid{
  display: flex;
  flex-direction: row;
  justify-content: right;
  position: relative;
}

.vidCombineLink{
  width: 450px;
  height: 250px;
  position: relative;
}

.vidCombine{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.playHome{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: white;
  font-size: 2rem;
  
}

.redHeaderHome {
  font-size: 20px !important;
  color: var(--darkRed);
  text-transform: uppercase;
  padding-bottom: 0rem !important;
}



.vidCombineLink:hover  .playHome{
  color: var(--darkRed);
}

.pIL{
  display: flex;
  flex-direction: row;
justify-content: space-evenly;
  /* align-items: center; */
  /* padding: 0rem 4rem 0rem 4rem; */
  /* background-color: black; */
  margin: 0px;
  padding: 0rem 4rem 0rem 4rem;
}
.leftHL{
  display: flex;
  flex-direction: row;
  width:50%;
  align-items: left;
  justify-content: left;

}

.redHeaderHome{
  padding: 0rem 0rem 4rem 0rem;
}

.highlightsRow{
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0rem 0rem 4rem 0rem;
  background-color: #f5f5f5;
 
}

.highlights1{
  display: flex;
  justify-content: center;
}
.highlights2{
  display: flex;
  justify-content: center;
}

.toolsSecCont{
  padding: 0rem;
  margin: 0rem;
  overflow: visible; 
  background-color: #ffffff;
}

.toolsSec{
  overflow: visible; 
  padding: 2rem 4rem 2rem 2rem;
}
.sec-bg{

  background-color: #ffffff;
}
.colProjHi{
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.headerContPI{
  display: flex;
  flex-direction: row;
}

.headerContPI > .arrowPI{
  font-size: 20px !important;
  color: var(--darkRed);
  /* padding-top: 4.5rem; */
}

.last_row{
  display:flex;
  height:100%;
  margin-bottom: 2%;
  overflow: hidden;
}
@media screen and (max-width: 994px){
 
  .imgbg {
    position: relative; /* Make sure the pseudo-element is positioned relative to this container */
    width: 100%;
  }
  .homeVid{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: -6%;
    margin-top: 5%;
  }
  .imgbg::before {
      
      background-size: cover; /* Ensure the image fits within the container */
      background-position: center; /* Center the image within the container */
    
  }
  .highlightsRow{
    gap: 35px;
    padding-left: 1rem;
    padding-right: 4rem;
  }  
  .cuttingEdgeWrapper {
    display: flex;
    flex-direction: row;
    justify-content: start;
    height: fit-content;
    background-color: rgb(167, 173, 192);
  }
  .torso-img{
  justify-content:center ;
 
  height: 100%;
  width: 80%;
 


  }
  .cuttingEdge {
    line-height: 30px;
    display: flex;
    font-size: 24px !important; /* Set a specific font size */
    flex-direction: row;
    flex-wrap: wrap;
    color: black !important;

    /* Add spacing between words */
    word-spacing: 0.2rem !important; /* Adjust this value for more or less space */

    /* Other styles */
}
  .cuttingEdgeCont {
    height: fit-content;
    width:50%;
    margin-left: 1rem;

  }
  
  .about {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .highlightsRow{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .toolsSec{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .toolsSecLi{
    margin-top: 10%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
   
  }
  .tools-col{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
 
.tools-col-end{
  box-sizing: border-box;
    width: 100%;
    height: fit-content;
    overflow: hidden;
    margin-bottom: 5%;
}
}


.boxH {
  box-shadow: -5px -5px 9px rgba(255, 255, 255, 0.45),
    5px 5px 9px rgba(94, 104, 121, 0.3);
}
