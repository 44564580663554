.footer {
  background-color: black;
  padding: 2.5rem 4rem 2.5rem 4rem;
  /* height: 24rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.NLogoWhite{
  width: 170px;
  /* padding-bottom: 35px; */
  padding-top: 3px;
  align-self: center;
  float: right;
}
.navFooter {
  display: flex;
  flex-direction: row;
  padding-bottom: 35px;
  gap: 25px 35px;
 position: relative;
 flex-wrap: wrap;
 overflow: hidden;
}
.navPart{
  display: flex;
  flex-direction: row;
  gap: 25px 35px;
}

.navFooter  .footerLink{
  color: white;
}
.combineFooter{
  color: var(--darkRed);
}

.socFoot{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 15px;
  
}

.sec2Foot{
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 35px;
  padding-top: 35px;
  color: white;
}

.sec2Foot  a{
  color: white;
}



/* .circleFoot{
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.socFoot > .circleFoot > a {
  color: white;
  font-size: 1.3rem;
 
}

.contactFoot{
  display: flex;
  gap: 35px;
 
}

.contactFoot a, .contactFoot p{
  color: white;
}

.hrLineFoot{
  margin-top: -3px;
  border-top: 3px solid var(--grayBlue);
  opacity: 1;
}



.contactHeadersFoot{
  font-size: 1rem !important;
  color: var(--darkRed);
  margin-bottom: 5px !important;
}

.contactSecFoot{
  width: 230px;
 margin: 0px;
 padding: 0px;
}


.copyrightRow{
  display: flex;
  flex-direction: row;
}


.copyCol{
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: 0px;
  padding: 10px;
}

.copyright{
  color: white;
  font-size: 12px !important;
  
}


.footerLogoNav{
  width: 150px;
  height: fit-content;
  opacity: 1;
  margin-top: -30px;
}

.row3Footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 35px;
  margin-bottom: -16px;
  
}

.navRowFooter{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

}

.footerLogo{
  width: 200px;
  /* margin-top: -60px; */
  height: fit-content;
  opacity: 1;
  float: right;
}
.whiteLogo{
  width: 170px;
  height: auto;
  float: right;
  /* margin-top: 15px; */
}

.contactSecFoot > a > p{
  padding-bottom: 5px;
  margin: 0px;
}

@media screen and (max-width: 963px){
  .whiteLogo{
    align-self: end;
  }
}
@media screen and (max-width: 908px){
  .NLogoWhite{
   margin-top: 45px;
  }
}
@media screen and (max-width: 587px){
  .NLogoWhite{
   margin-top: 85px;
  }
}

