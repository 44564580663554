.submitButton:hover {
}

.submitButton {
  background-color: var(--red);
  border: var(--red);
  border-radius: 0px;
  width: 90px;
  height: 40px;
  /* box-shadow: inset -5px -5px 9px rgba(255, 255, 255, 0.45),
    inset 5px 5px 9px rgba(94, 104, 121, 0.3); */
}
