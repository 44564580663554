.pdfRow {
}

.pdfRow h4 {
  font-size: 1.2rem;
}

.pdfRow h5 {
  font-size: 1rem;
}

.featPubCit {
  font-size: 0.9rem !important;
}

.onePub {
  display: flex;
  flex-direction: row;
  gap: 35px;
  justify-content: center;
  width: fit-content;
}

.test123 {
  width: fit-content;
}
