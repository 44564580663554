.projectDescr {
  text-align: center;
}
.descr {
  border-left: solid red 3px;
}

.stylingNum {
  font-size: 1.8rem;
  padding-right: 1rem;
}

.p5img{
  width: 400px;
}

.researchPaperRow {
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  margin-bottom: 4rem;
}
.program1Pics{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.captionsPics{
  font-size: 12px !important;
  font-style: italic;
  padding-top: 20px !important;
 
  margin: 0px !important;
}
.captionsPicsCar1{
  font-size: 8px !important;
  font-style: italic;
  padding-top: 5px !important;
  margin: 0px !important;
}

.captionsPicsCar{
  font-size: 8px !important;
  font-style: italic;
  padding: 0px !important;
  margin: 0px !important;
}

.carCaptions{
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
}
.contPdf{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carouselProjects {
  width: 420px;
  height:320px;
}

.carouselProjects .carousel-control-prev,
.carouselProjects .carousel-control-next {
    display: none;
  
}
.carCont{
  display: flex;
flex-direction: row;
justify-content: center;
}

.pdfproj{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nprIframe{
  width: 420px;
}

.researchPaper {
  display: flex;
  flex-direction: column;
  gap: 35px;
  width: fit-content;
}

.pdfCont {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.projectsCol {
  display: flex;
  flex-direction: row;
}

.media {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projSec {
  /* margin-top: 4rem; */
  /* box-shadow: inset 5px 5px 12px #e0e0e0, inset -5px -5px 12px #fcfcfc; */
  padding: 35px 35px 0px 35px;
  border-radius: 10px;
}

.projSecLast{
  padding: 35px 35px 0px 35px;
}

.researchMedia {
}

.blackHeaderProjects {
  font-size: 25px !important;
  font-weight: 700;
  /* text-transform: capitalize; */
}

.redHeaderProjects {
  font-size: 20px !important;
  font-weight: 700;
  /* color: var(--darkRed); */
}

.figureDescCont {
  border: solid black 2px;
  padding: 0.5rem;
}

.figureDesc {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0px;
}

.blackHeaderResearchsmNum{
  font-size: 15px !important;
  font-weight: 700;
  width: fit-content;
  padding-top: 35px;
  padding-right: 10px;
}
.projectsDesc{
  padding-left: 35px;
}


.hack {
  margin-top: 8px;
}

/* .graphical1{
  margin-right: 35px;
} */

@media screen and (max-width: 992px) {
  .researchPaper {
    margin-top: 2rem;
  }
  .carouselProjects {
    width: 320px;
    height:320px;
  }
  
  .graphical1{
    margin-right: 0px;
  }
  .researchPaperRow{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 35px;
    margin-bottom: 1rem;
  }
  .carCont{
    display: flex;
  flex-direction: row;
  justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .blackHeaderResearchsmNum{
    font-size: 13px !important;
    /* padding-right: 5px; */
  }




}

@media screen and (max-width: 576px){
  .projSec{
    padding: 35px 15px 0px 0px;
   margin-bottom: -35px;
  }
  .projSecLast{
    padding: 35px 15px 0px 15px;
   margin-bottom: 4rem;
  }

  .projectsCol{
    /* padding: 0px;
    margin: 0px; */
  }
 .graphical1{
  padding-right: 10px;
 }
 .blackHeaderProjects{
  align-self: center;
  padding-top: 35px;
  padding-left: 5px;
 }
 .blackHeaderResearchsmNum{
  padding-left: 0px;
}
.projectsDesc{
  padding-left: 15px;
}

}
