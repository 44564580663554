.iconButton {
  border-radius: 10px;
  /* background-color: white; */
  /* color: white; */
  border: white;
  background-color: white;
  padding: 3px 9px 3px 9px;
  /* box-shadow: inset -5px -5px 9px rgba(255, 255, 255, 0.45),
    inset 5px 5px 9px rgba(94, 104, 121, 0.3); */

  /* box-shadow: inset -5px -5px 9px rgba(255, 255, 255, 0.45),
    inset 5px 5px 9px rgba(94, 104, 121, 0.3); */
  border: solid rgba(0, 0, 0, 0.05) 1px;
  box-shadow: 1px 1px 1px #c7c4c4, -1px -1px 1px #fff;

  height: 32px;
  text-shadow: 1px 1px 1px #c7c4c4, -1px -1px 1px #fff;
}

.iconButton:hover {
  background-color: rgba(var(--softBluergb), 0.2);
  border: solid rgba(var(--softBluergb), 0.2) 1px;
}

.iconButton:active {
  background-color: rgba(var(--softBluergb), 0.2);
  border: solid rgba(var(--softBluergb), 0.2) 1px;
}
