.publications {
  padding-top: 2rem;

}
.featuredPub {
  padding: 0px 15px 15px 15px;
  display: flex;
  flex-direction: row;
  background-color: #f5f5f5;

  /* gap: 15px; */
  /* padding: 2rem 4rem 0rem 4rem; */
   /* padding-top: 4rem; */
  position: relative;
  /* background-color: var(--grayBlue); */
  /* border-top: solid var(--grayBlue) 2px; */
  margin: 0px;
  /* border-bottom: solid var(--grayBlue) 2px; */
}

.bgPub{
  margin-left: -4rem;
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  width: 100%;
  /* opacity: .85; */
  height: 650px;
  object-fit: cover;
}
.redHeaderPub{
  font-size: 20px !important;
  font-weight: 700;
  color: var(--darkRed);
  text-transform: uppercase;
  padding: 1rem 4rem 1rem 1rem;
}

.featuredPubRow {
  display: flex;
  flex-direction: row;
  
  /* gap: 35px; */
  
 
  /* color: white; */
 
  /* background-color: var(--grayBlue); */
  /* padding: 15px; */
}
.hrHeroFeat{
  margin-right: 10px;
}

.firstF{
  /* padding-bottom: 35px; */
}

.heroFeatTextCont{
display: flex;
flex-direction: column;
justify-content: space-between;
}
.heroFeatText{
  /* background-color: white; */
  /* color: white !important; */
 
  padding: 0px 0px 0px 15px;
   /* background-color: black; */
   /* color: white; */
 
}
.featuredWord{
  font-style: italic;
  padding-left: 15px;
  font-size: .7rem;

}
.featuredJournal{
  font-size: 20px !important;
  font-weight: 700;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* background-color: black; */
  /* background-color: white; */
  /* color: white; */
}

.journalVol{
  font-size: .9rem !important;
  /* background-color: white; */
  /* background-color: black;
  color: white */
}
.scImg {
  width: 210px;
  height: 270px;
}

@media screen and (max-width: 768px) {
   .featuredJournal{
    font-size: 16px !important;
    -webkit-line-clamp: 2;
  }
  .journalVol{
    font-size: 12px !important;
  }
  
}

@media screen and (max-width: 576px) {
  .publicationsWrapper {
    justify-content: center;
  }
  .publicationsCont {
    margin: 0px;
  }
  .scImg {
    width: 130px;
    height: 195px;
  }
  .featuredPub {
    padding: 1.5rem;
  }
  .featuredJournal{
    font-size: 16px !important;
    -webkit-line-clamp: 2;
  }
  .journalVol{
    font-size: 12px !important;
  }
  .heroFeatText{
    padding: 5px;
  }

    .featuredPubRow{
      gap:15px;
    }

    .redHeaderPub{
      padding-left: 1rem;
    }
    .publications {
      padding-top: 1rem;
    
    }
    .hrHeroFeat{
      margin-right: 0px;
    }
  
}


