.researchPDF {
  width: 420px;
  height: auto;
  border: solid black 1px;
}

.researchPDF2 {
  width: 420px;
  height: auto;
  border: solid black 1px;
}
.aspect-ratio-box {
  position: relative;
  width: 100%;
  padding-bottom: 56%; /* This gives a 16:9 aspect ratio (height/width = 9/16 = 0.75) */
}

.aspect-ratio-box iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; /* Optional to remove border */
}

@media screen and (max-width: 576px){
  .researchPDF{
    width: 250px;
    margin-top: 35px;
  }
  .aspect-ratio-box {
    position: relative;
    width: 100%;
    padding-bottom: 56%; /* This gives a 16:9 aspect ratio (height/width = 9/16 = 0.75) */
  }
}

@media screen and (max-width: 768px){
  .pdfproj{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .aspect-ratio-box {
    position: relative;
    width: 100%;
    padding-bottom: 56%; /* This gives a 16:9 aspect ratio (height/width = 9/16 = 0.75) */
  }
}