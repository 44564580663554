.methodsRow {
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
}

.carContM{
  display: flex;
  flex-direction: row;
  padding-top: 3rem;
  margin-left:7%;
}
.carContM1{
  display: flex;
flex-direction: row;
margin-left:10%;

padding:36px;
}
.MCar{
  width:100%;
  margin-left:10%;

}
.MCar2{
  width:100%;
  margin-left:10%;
  
}
.methodsCol {
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
}
.researchPub{
  width: 100px;
}
.ligand{
  width: 320px;
}
.piImg{
  width: 70%;
}

.borderArt{
  border: 1px solid black;
}

.articleMethod{
display: flex;
flex-direction: column;

}

.articleMethodCit{
  padding: .5rem;
text-align: left;
margin: 0px;
font-size: 12px;
}


.mDimg2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .methodsRow h4 {
  font-size: 21px !important;
  font-weight: 700;
} */

.methodsSec {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.method1Imgs {
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 250px; 
  /* width: 100%; */
}

.function{
  /* width: 1000px; */
  margin-left: -100px;
}

.method2Imgs {
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: center;
  /* justify-content: center; */
  width: fit-content;
  /* width: 100%
  ; */
}
.mcImg{
  /* width: 300px; */
}
.mcImg2{
  /* width: 250px;
  height: 160px;
  margin-top: 35px; */
}
.letter {
  /* text-shadow: 1px 1px 1px #c7c4c4, -1px -1px 1px #fff; */
  /* font-size: 5rem; */
}
.functionName {
  font-size: 0.8rem !important;
  width: 200px;
  margin-left: -50px;
  /* padding-left: 15px; */
}


.functionDesc {
  margin-left: 120px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;

  /* margin-bottom: -60px; */
}


.methodBox1 {
  padding-left: 35px;
  padding-top:35px;
  display: flex;
  flex-direction: row;
  /* border-bottom: solid var(--darkRed) 3px;
  border-right: solid var(--darkRed) 3px; */
}
.methodBox2 {
  padding: 35px;
  /* border-bottom: solid var(--darkRed) 3px; */
}
.methodBox3 {
  padding: 35px;
  /* border-right: solid var(--darkRed) 3px; */
}
.methodBox4 {
  flex-direction: row;
  padding: 35px;
  /* border-bottom: solid var(--darkRed) 3px; */
}

.methodHeader {
  width: fit-content;
}

.methodsRow {
  display: flex;
  justify-content: space-around;
}

.methodsArt{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lastMethodArt{
  margin-bottom: 2rem;
}
@media screen and (max-width: 768px) {
  .methodsSec {
    display: flex;
    flex-direction: column;
    gap: 35px;
  }
  
}

@media screen and (max-width: 576px){
  .methodsCol {
    display: flex;
    flex-direction: column;
  }
  .method2Imgs{
    gap: 20px;
  }
}
