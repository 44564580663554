.toolsRow3D {
  padding-left:4rem;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
}


.toolRow1 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: hidden;
  
    align-content: center;

}

.toolsHeader{
  display: flex;
  justify-content: space-between;
  font-size: large;
  align-content: center;
  width:100%;
 
}
.Hands_col{
  height:50%;
}
.featuredTools {
  margin-top: auto;
}

.featuredToolsCont {
  display: flex;
  justify-content: space-around;
  
  margin-bottom: 40px;
}
.Link_button{
  display: flex;
  
  justify-content: center; /* Center horizontally */
  align-items: center;
  
}
.shiny-row {
  display: flex;
  height:25% !important;
  align-items: center;
  background: #f5f5f5; /* Base background color */
  background: linear-gradient(135deg, #f5f5f5 25%, #ffffff 75%); /* Adding a subtle gradient for shine */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05); /* Optional: a light shadow for more depth */
  border-radius: 8px; /* Optional: rounding the corners */
  box-shadow: 
  0 2px 5px rgba(0, 0, 0, 0.05),  /* General light shadow for depth */
  0 8px 15px rgba(0, 0, 0, 0.15);
  margin-bottom: 1%;
  padding-left: 5%;
}
.shiny-row1 {
  display: flex;
  height: 25%;
  background: #f5f5f5; /* Base background color */
  background: linear-gradient(135deg, #f5f5f5 25%, #ffffff 75%); /* Adding a subtle gradient for shine */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05); /* Optional: a light shadow for more depth */
  padding-left: 5%;
  border-radius: 8px; /* Optional: rounding the corners */
  box-shadow: 
  0 2px 5px rgba(0, 0, 0, 0.05),  /* General light shadow for depth */
  0 8px 15px rgba(0, 0, 0, 0.15);
  margin-bottom: 50%;
}
.aligntoolname{
display:flex;
background-image: url('/public/images/bg-row.jpeg');
align-items: center;
}
.Whitebg{
  border-radius: 15px;
  background-color: #ffffff;
}
.full_width{width: 100%;}
.aligntoolimg{
display:flex; 
justify-content: center;
align-items: center;
}
.featToolLink {
  color: black !important;
  
  font-size: larger;
  
}
.toolsDescription1 {
    opacity: 1;
    padding-top:5%;
    width: 100%;
    height: 100%;
    color:white;
    display: flex;
    flex-direction:column;
    background-color: #1a3242;
    border-radius: 7px;
}
.feattoolsDescription1{
  opacity: 1;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction:column;
    
    border-radius: 7px;
}
.toolsDescription1 a {
  color: white;
  text-decoration: underline; 
}

.font{
  
  font-size: 20px;
}
.Handmole{
 
  height: 170px;
  width:200px;

}
.toolIcons2 {
  flex-wrap: wrap;
  opacity: 1;
  margin: 0px;

  padding-top:3rem;
  display: flex;

  flex-direction:row;
  justify-content: space-between;
  background-color: #1a3242;
}
.Newtools{
  margin-top: 10rem;
  justify-content: space-between;

}
.iconTool {
  width: 1000px;
  height:100px;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black;
}
.iconToolSpecial {
  width: 1000px;
  height:80px;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black;

}
@media screen and (max-width: 375px) {
  .toolRow {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
