.pdf {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 2rem;
}

.navPdfCol {
  margin-left: 215px;
  margin-bottom: 2.2rem;
}

.pdfRow {
  display: flex;
  margin: 0px;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 2.2rem;
}

.pdfImage {
  width: 200px;
  height: auto;
}

.pdfBorder {
  border: solid black 1px;
  box-shadow: 1px 1px 1px #c7c4c4, -1px -1px 1px #fff;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}
.featTitle{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* color: white; */
}

.authors {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  /* color: white; */
}

.pdf h4 {
  font-size: 1.2rem !important;
  margin-bottom: 0.5rem !important;
  font-weight: 700;
}

.pdf h5 {
  font-size: .9rem !important;
}

.featPDF h4{
  font-size: 1.2rem !important;
  margin-bottom: 0.5rem !important;
  font-weight: 700;
  /* color: white; */
  /* background-color: white; */
}
.featPDF h5{
  font-size: .9rem !important;
  /* color: white; */
  /* background-color: white; */
}

.h5Cit {
  font-size: 1rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.pdfLink {
  text-decoration: none;
  color: black;
}

.downloadPdf {
  color: red;
}
.pdfLinks {
  margin-top: -30px;
  padding: 0px;
  display: flex;
  gap: 5px;
  /* text-shadow: 1px 1px 1px #c7c4c4, -1px -1px 1px #fff; */
}

.starColor {
  color: purple;
  font-size: 2rem;
  padding-left: 15px;
  width: fit-content;
}

.featured {
  display: flex;
  flex-direction: row;
}

.viewerPdf {
  color: red;
}

.postersRow {
  padding-left: 4rem;
  padding-right: 4rem;
  margin-bottom: 2.2rem;
}

.poster {
  /* border: solid black 2px; */
}

/* .pub3D {
  box-shadow: inset 5px 5px 12px #e0e0e0, inset -5px -5px 12px #fcfcfc;
  padding: 16px;
  border-radius: 10px;
} */

@media screen and (max-width: 992px) {
  .pdf h4 {
    font-size: 1.2rem;
  }

  .pdf h5 {
    font-size: 0.9rem;
  }

  .h5Cit {
    font-size: 0.8rem !important;
  }
  /* .featTitle{
    -webkit-line-clamp: 2;
  } */
}

@media screen and (max-width: 576px) {
  .title {
    -webkit-line-clamp: 2;
  }
  .authors {
    -webkit-line-clamp: 2;
  }
  .h5Cit {
    -webkit-line-clamp: 2;
  }

  .navPdfCol {
    margin-left: 0px;
  }
  .pdf h3 {
    font-size: 0.9rem;
  }

  .pdf h4 {
    font-size: 0.5rem;
  }

  .pdf h5 {
    font-size: 0.4rem;
  }
  .featPDF h4{
    font-size: 0.9rem !important;
    margin-bottom: 0.5rem !important;
    font-weight: 700;
  }
  .featPDF h5{
    font-size: 0.9rem !important;
  }
  .pdf {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 35px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .pdfImage {
    width: 150px;
    height: auto;
  }
  .pdfRow {
    display: flex;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 2.2rem;
    flex-direction: row;
    justify-content: start;
  }
  .navPdfCol {
    margin-left: 15px;
    margin-bottom: 0px;
  }

  .postersRow {
    padding-left: 0rem;
    padding-right: 0rem;
    margin-left: 0rem;
    margin-right: 0rem;
  }
  
}
@media screen and (max-width: 768px){
  .featPDF h4{
    font-size: 0.9rem !important;
    margin-bottom: 0.5rem !important;
    font-weight: 700;
  }
  .featPDF h5{
    font-size: 0.9rem !important;
  }
}

@media screen and (max-width: 423px) {
  .pubArticleCol {
    width: 50%;
  }
}

.navUnderlined {
  /* text-decoration: underline; */
}
/* .navUnderlined.active {
  text-decoration: none !important;
}
.navUnderlined {
  position: relative;
  text-decoration: none !important;
}

.navUnderlined::after {
  content: "";
  position: absolute;
  bottom: -3px; 
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #007bff; 
} */

/* .navUnderlined {
  border-bottom: 3px solid #868686 !important; 
}

.navUnderlined.active {
  border-bottom: 3px solid black !important; 
} */

.navUnderlined {
  border-bottom: 3px solid rgba(var(--electricBluergb), 0.3) !important;
  /* text-shadow: 0.5px 0.5px 0.5px #c7c4c4, -0.5px -0.5px 0.5px #fff !important; */
}

.navUnderlined.active {
  border-bottom: 3px solid var(--electricBlue) !important;
  color: var(--darkRed) !important;
  /* text-shadow: 0.5px 0.5px 0.5px #c7c4c4, -0.5px -0.5px 0.5px #fff; */
}
.navUnderlined:hover {
  color: black;
  text-decoration: none;
}
