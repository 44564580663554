.embed {
    width: 100%;
    height: 100%; /* Let the iframe height adjust automatically */
    min-height: 410px; /* Set a minimum height for the iframe */
    border: 0; /* Remove borders if necessary */
    box-sizing: border-box;
    
}
.shiny-row2 {
    height: 75% !important;
    background: #f5f5f5; /* Base background color */
    background: linear-gradient(135deg, #f5f5f5 25%, #ffffff 75%); /* Adding a subtle gradient for shine */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05); /* Optional: a light shadow for more depth */
    padding: 20px;
    border-radius: 8px; /* Optional: rounding the corners */
    box-shadow: 
    0 2px 5px rgba(0, 0, 0, 0.05),  /* General light shadow for depth */
    0 8px 15px rgba(0, 0, 0, 0.15);
    margin-bottom: 1%;
  }
.linkedInCont {
    padding-left: 4rem;
    width: 100%;
    height:100%;
    overflow: auto;
    /* Prevents overflow into the footer */
}
.toolsRow3D {
    padding-left:4rem;
    width:100%;
    height:100%;
  }
@media screen and (max-width: 992px){
  .embed{
    width: 100%;
    height: 610px;
   
 }
    .linkedInCont{
        padding-left:4rem;
        width: 100%;
        height: auto;
        
    
    }
}