.carouselRow {
    display: flex;
    flex: row;
    justify-content: space-evenly;
    width:100%;
}
  
.myCarousel {
    width: 100%;
    max-width:1000px ;
    margin: auto;
  }
   
.carouselPub {
    width: 100%;
    height: 320px;
}

.vidImgCar{
    width: auto;
    height: 200px;
}

.articleCar{
    /* margin-top: -10px; */
    /* padding-left: 15px;
    padding-right: 15px; */
}
.articleCar > h4 {
   margin-top: -8px;
   margin-bottom: 12px;
    /* padding: 0px !important; */
    line-height: 1.2rem !important;
}
.articleCar > h5 {
    margin-top: -8px !important;
    /* padding: 0px !important; */
    /* line-height: 1rem !important; */
}

.carousel-control-prev,
.carousel-control-prev,
.carousel-control-next {
  width: 50%; /* Adjust width to reduce space taken by arrows */
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-size: 100%, 100%;
}

.myCarousel .carousel-control-prev,
.myCarousel .carousel-control-next {
  padding: 3rem; /* Adjust padding as needed */
  top: 50%;
  transform: translateY(-50%);
}

.carousel-indicators{
  margin: -10px;
}


.carousel .col {
    display: flex;
    flex: row;
    justify-content: center;
}
.nytBorder, .vidCarousel {
    width: 100%;
    max-width: 1000px; /* Ensure full width */
    margin: auto;
}

.nprIframe2Border {
    width: 100%;
    max-width: 1000px; /* Ensure full width */
    margin: auto;
}

.imgDesCar2, .imgDesCar {
    padding: 1rem;
    width: 100%;
}

.newyorktimes{
    background-color: black;
    color: white;
    height: 172px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nprContCar{
    display: flex;
    flex-direction: column;
    
}

.nytLink{
    text-align: end;
    text-decoration: none;
    color: #666;
    font-size: 0.9rem !important;
    font-style: italic;
}
.natureLink{
    text-align: end;
    padding-top: 55px;
    text-decoration: none;
    color: black;
    font-size: .9rem !important;
    font-style: italic;
    color: #666;
}

.vidLink{
    text-align: end;
    padding-top: 60px;
    text-decoration: none;
    color: black;
    font-size: .9rem !important;
    font-style: italic;
}

.vidCarousel{
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    width: 300px;
    height: 360px;
    border: solid #ebebeb 1px;
    position: relative;
    background-color: white;
}
.carVid{
    font-size: .8rem !important;
    text-decoration: none;
    color: black;
}
.carVidHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.vidCarIcon{
color: white;
position: absolute;
top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  z-index: 3;
}

.hrLineFoot2{
    margin-top: -3px;
    border-top: 3px ridge var(--grayBlue);
    opacity: .7;
  }

.iconBG{
    position: absolute;
    background-color:black;
    height: 15px;
    width: 15px;
    top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  z-index: 2;
}
.vidCarouselCont{
    position: relative;
   
}



.onePub h4{
    font-size: 1.2rem !important;
    margin-bottom: 0.5rem !important;
    font-weight: 700;
    /* color: white; */
    /* background-color: white; */
  }
  .onePub h5{
    font-size: .9rem !important;
    /* color: white; */
    /* background-color: white; */
  }


