.logos{
    display: flex;
    flex-direction: column;
    padding-bottom: 4rem;
    padding-right: 4rem;
    padding-left: 2.5rem;
}


.headerContPI{
    display: flex;
    flex-direction: row;
 
}

.logoCont2{
    width: 100px;
}


.unLogo{
    width: 110px;
    margin: 0px;
    padding: 0px;
}

.nature{
    width: 180px;
    margin: 0px;
    padding: 0px;
    margin-left: -25px;
}

.nprLogo{
    width: 180px;
    margin: 0px;
    padding: 0px;
}

.tumbleCont{
    border: solid green 5px;
    width: 70px;
    margin: 0px;
    padding: 0px;
}

.tumble{
    width: 180px;
    margin: 0px;
    padding: 0px;
    margin: 0px;
}

.iconsLog{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width:100% ;
    height: 100%;
    /* margin-left: -25px; */
    /* padding-left: 4rem; */
}



.nytLogo{
    width: 180px;
    height: 110px;
    padding: 0px;
  
    /* margin-right: -70px;
    margin-left: -70px; */
}

.npr{
font-size: .7rem;
font-family: "NPRSans", sans-serif;
font-weight: 400;
line-height: 1.5;
letter-spacing: .02em;
text-transform: none;
color: #333;
display: block;
color: #767676;
font-style: italic;
height: fit-content;
margin: 0px;

padding: 0px;
}

.nprCont{
    height: fit-content;
    width: fit-content;
    align-items: baseline;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    align-items: center;
    padding: 5px  0px 0px 0px;
    /* margin-left: -25px; */
}
.arrowPI{
    padding-left: 5px;
    padding-top: 5px;
    /* font-size: 1rem; */
}

@media screen and (max-width: 768px){
    .nytLogo{
        width: 180px;
        height: 50px;
        padding: 0px;
      
        /* margin-right: -70px;
        margin-left: -70px; */
    }
    
    .unLogo{
        width: 50px;
    }

    .tumble{
        width: 50px;
    }

    .nprCont{
        /* margin-left: -10px; */
    }
    .nprLogo{
        width: 80px;
    }
  }
  .logos{
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    padding-right: 4rem;
    padding-left: 2.5rem;
}

  @media screen and (max-width: 576px) {
    .logos{
       padding-left: 1rem;
       padding-right: 1rem; 
    }
    .nprCont{
        margin-left: -15px;
    }
  }