.applyPadding{
    padding: 4rem 4rem 4rem 4rem;
}

.apply {
  text-align: center;
  padding-bottom: 2rem;
}

.applyCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.emailApply {
  padding-left: 5px;
}

.rowApply {
  display: flex;
  flex-direction: row;
  justify-content: center;
  

}

.combine2{
  color: var(--darkRed);
}
.bottomContact{
  display: flex;
  justify-content: center;
  
} 