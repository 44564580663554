.researchButton {
  background-color: var(--red);
  border: var(--red);
  width: 220px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 900;
  border-radius: 0px;
  /* border-radius: 5px; */
  /* text-shadow: 1px 1px 1px var(--red), -1px -1px 1px var(--red); */

  /* box-shadow: 6px 7px 9px 9px var(--darkBlue); */

  /* text-shadow: x-offset y-offset blur color;
  -webkit-box-shadow: 0px 6px 0px var(--lightRed),
    0px 3px 15px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 6px 0px var(--lightRed), 0px 3px 15px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 6px 0px var(--lightRed), 0px 3px 15px rgba(0, 0, 0, 0.4);

  box-shadow: inset 0px 0px 3px rgba(255, 255, 255, 0.5);

  -webkit-box-shadow: 0px 6px 0px var(--lightRed),
    0px 3px 15px rgba(0, 0, 0, 0.4), inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 0px 6px 0px var(--lightRed), 0px 3px 15px rgba(0, 0, 0, 0.4),
    inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);
  box-shadow: 0px 6px 0px var(--lightRed), 0px 3px 15px rgba(0, 0, 0, 0.4),
    inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5); */
}

.researchButton:hover {
  background-color: var(--darkRed);
}

.button3D {
  /* width: fit-content;
  height: fit-content; */
  padding: 15px;
  /* box-shadow: inset -5px -5px 9px rgba(94, 104, 121, 0.3),
    inset 5px 5px 9px rgba(94, 104, 121, 0.3); */
}

.researchButton {
  /* background-color: white; */
  /* border: solid var(--red) 3px; */
  /* color: var(--red); */

  /* transition: all 0.5s; */
}

/* .researchButton:hover,
.researchButton:focus {
  background-color: var(--red);
  border: var(--red);
  color: white;
} */
/* .researchButton:active {
  background-color: var(--red);
  border: var(--red);
  color: white;

  -webkit-box-shadow: 0px 2px 0px var(--lightRed),
    0px 1px 6px rgba(0, 0, 0, 0.4), inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 0px 2px 0px var(--lightRed), 0px 1px 6px rgba(0, 0, 0, 0.4),
    inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);
  box-shadow: 0px 2px 0px var(--lightRed), 0px 1px 6px rgba(0, 0, 0, 0.4),
    inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);

  -webkit-transform: translate(0, 4px);
  -moz-transform: translate(0, 4px);
  transform: translate(0, 4px);
} */

@media screen and (max-width: 1030px) {
  .researchButton {
    padding: calc(0.5rem + 0.1vw) calc(1.5rem + 0.5vw);
  }
}
