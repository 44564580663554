.navbarPositioning {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin: 0rem 4rem 1.5rem 4rem; */
  align-items: center;
  /* background-color: var(--lightBlue); */
  padding: 0rem 1rem .5rem 4rem;
  /* background-color: #f5fbff; */
}

.northeastern {
  font-family: Lato, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.brandLink {
  text-decoration: none;
  /* color: white; */
}
.brandPositioning {
  display: flex;
  gap: 2px;
  width: fit-content;
}

.logoCont {
  width: 200px;
  display: flex;
}

.titleContainer {
  /* width: 100px; */
}

.orgTitle {
  text-wrap: wrap;
  /* line-height: 1rem; */
  font-weight: 600;
  font-size: 2rem;
  font-family: "";
}

.combine {
  color: #c8102f;
}
.lab {
  text-align: end;
}

.logo {
  width: calc(5.5rem + 30vw);
  /* prevents the logo stretching vertically with display flex */
  align-self: center;
  max-width: 200px;
}

.navbar-nav {
  display: flex;
}

.nav-link {
  font-size: 15.35px;
  font-weight: 500;
  /* color: black; */
  line-height: 22.7px;
  width: fit-content;
  color: #00293d;
  /* text-shadow: -8px -8px 12px rgba(255, 255, 255, 0.4),
    8px 8px 12px rgba(0, 0, 0, 0.08);
  text-shadow: 0.5px 0.5px 0.5px #c7c4c4, -0.5px -0.5px 0.5px #fff; */
}

.nav-link:hover {
  border-bottom: solid var(--darkRed) 2px;
  text-shadow: 0.5px 0.5px 0.5px #c7c4c4, -0.5px -0.5px 0.5px #fff;
}

.divider {
  align-items: center;
  align-self: center;
  text-align: center;
}

.dmTog {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.navbar-toggler {
  color: white;
  /* border: solid rgba(83, 149, 196, 0.5) 1px; */
  /* box-shadow: 1px 1px 1px #c7c4c4, -1px -1px 1px #fff; */
  border: solid rgba(0, 0, 0, 0) 1px;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0,0,0, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.joinStyling {
  padding-left: 5px;
}

@media screen and (max-width: 576px) {
  .navbarPositioning {
    padding: 0rem 1rem 0rem 1rem;
  }
}

/* Minkara COMBINE Lab Hex Codes 

 

Red: ED1C24 

Light blue: 5495C4 

Dark blue: 335975 

Grey: A7A9AC  */

/* .test12 {
  text-shadow: var(--brShadow), var(--tlShadow);
  position: relative;

  &::before,
  &::after {
    background-color: var(--colorMain);
    content: "";
    border-radius: 10%;
  }

  &::before {
    left: 0;
    width: 100px;
    height: 100px;
    top: -150px;
    z-index: 10;
    box-shadow: inset var(--brShadow), inset var(--tlShadow);
  }
  &::after {
    left: -50px;
    width: 200px;
    height: 200px;
    top: -200px;
    box-shadow: var(--brShadow), var(--tlShadow);
  }
} */
