.homeContact {
  object-fit: cover;
  width: 200px;
}

.contactHomeHeader{
  font-size: 20px !important;
  color: var(--darkRed);
  text-transform: uppercase;
}

.contactHomeInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contactHomeInfo p {
  margin-bottom: 0px;
}

.email {
  display: flex;
  gap: 5px;
}

.contactHomeCont {
  padding-left: 4rem;
  padding-right: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contactDisplay {
  display: flex;
  justify-content: center;
  /* box-shadow: inset 5px 5px 12px #e0e0e0, inset -5px -5px 12px #fcfcfc; */
  padding: 16px;
  border-radius: 10px;
  width: fit-content;
  
}

@media screen and (max-width: 768px) {
  .contactHomeInfo {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 576px) {
  .contactHomeCont {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
