.privacyPolicy{
    padding: 0rem 4rem 2rem 4rem;
}

.pp{
  
        font-size: 20px !important;
        color: var(--darkRed);
        text-transform: uppercase;
        padding-top: 2rem;
      
}
.privacyPolicy div, header{
    padding-bottom: 1.5rem;
}
.privacyPolicy h3{
    font-size: 20px !important;
    color: var(--darkRed);
    text-transform: uppercase;
    padding-bottom: 0rem !important;
}

.privacyPolicy h5{
    font-size: 16px !important;
    color: var(--darkRed);
    text-transform: uppercase;
    padding-bottom: 0rem !important;
    font-weight: 700;
}

@media screen and (max-width: 576px){
    .privacyPolicy{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}