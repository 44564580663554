.memberPic {
  width: 300px;
  height: 300px;
  /* min-width: 350px;
  min-height: 350px; */
  object-fit: cover;
}

.floatImgMona{
  float: left;
  margin-right: 3rem;
  margin-bottom: 12rem;
}
.h1{
  padding-bottom: 2 rem;
}

.floatImg {
  float: left;
  margin-right: 3rem;
  margin-bottom: 2rem;
}

.group2Row {
  display: flex;
  flex-direction: row;
}

.rowGroup {
  display: flex;
  flex-direction: row;
}
.groupRowCont {
  /* width: 100%; */
}

.img2ContAlumni {
  display: flex;
  flex-direction: row;
  font-size: 1rem !important;
  flex-wrap: wrap;
  gap: 5px;
}

.bioText {
  margin-left:25%;
  /* display: flex;
  flex-direction: column; */
}
.groupName {
  margin-top: -10px;
  font-size: 20px !important;
  font-weight: 700;
  padding-bottom: 1%;
}
.groupNameAl {
  font-size: 20px !important;
  font-weight: 700;
}

.groupTitle {
  font-size: 0.9rem !important;
  font-weight: 700;
}
.eduspace{
  margin-top: 0.5rem;
}
.groupTitle2 {
  font-size: 0.9rem !important;
  font-weight: 700;
  margin-bottom: 30px;
}
.groupTitle21 {
  margin-top: -0.4rem;
  font-size: 0.9rem !important;
  font-weight: 700;
  
}
.bio {
  display: flex;
  justify-content: space-between;
}

.img3D {
  width: fit-content;
  height: fit-content;
  padding: 35px;
  /* box-shadow: inset -5px -5px 9px rgba(255, 255, 255, 0.45),
    inset 5px 5px 9px rgba(94, 104, 121, 0.3); */
  /* box-shadow: inset 5px 5px 12px #e0e0e0, inset -5px -5px 12px #fcfcfc; */
  border-radius: 5px;
}
.cont3D {
  padding: 4rem;
  border-radius: 5px;
  /* box-shadow: inset -5px -5px 9px rgba(255, 255, 255, 0.45),
    inset 5px 5px 9px rgba(94, 104, 121, 0.3); */
  /* box-shadow: inset 5px 5px 12px #e0e0e0, inset -5px -5px 12px #fcfcfc; */
}

.h33D {
  /* background-color: rgb(var(--softBluergb)); */
  width: fit-content;
  /* color: white; */
}

.centerGroup {
  font-style: italic;
}

.positionsGroup {

  padding-top: 0.5rem;
 
  /* width: fit-content; */
  /* border-bottom: solid var(--darkRed) 2px; */
}
.aboveBorder {
  display: flex;
  justify-content: space-between;
  margin: 0px;
  padding: 0px;
}
.groupSoc1 {
  /* color: var(--darkRed); */
  display: flex;
  padding-right: 5px;
  flex-direction: row;
  justify-content: end;
}
.groupSoc {
  /* color: var(--darkRed); */
  display: flex;
  padding-right: 5px;
  padding-bottom: 5px;
  flex-direction: column;
  justify-content: end;
}
.groupSocCont{
display: flex;
flex-direction: row;
gap: 5px;
}

.groupSocCont  a {
  text-decoration: none;
  color: black;
}



.mLogo{
  width: 20px;
  background-color: black;
  padding: 1px;
}

.hrLine {
  margin-top: -3px;
  border-top: 3px solid var(--darkRed);
  opacity: 1;
}

.education {
  padding-top: 0.3rem;
  font-size: 0.8rem !important;
  display: flex;
  flex-direction: column;
}
.education1 {
  padding-top: 0.3rem;
  font-size: 0.8rem !important;
  display: flex;
  align-self: start;
  flex-direction: row;
}

.education  p{
 font-style: italic;
 margin: 0px;
}

.edpH{
  font-weight: 600 !important;
}

.edp{
  font-style: italic;
 margin: 0px;
 font-size: 0.8rem !important;
}

 /* .descEd{
  padding-left: 15px !important;
} */

.descEdHead{
  padding-bottom: 5px;
}

.descEd{
  font-size: .7rem !important;
}

.funFact {
  margin-top: -10px;
  font-size: 0.9rem !important;
  padding-top: 0.2rem;
}

.researchGroup {
  padding-top: 0.3rem;
}

.researchText {
  margin-top: 10px;
  font-size: 0.9rem !important;
}

.programText{
  font-size: 0.8rem !important;
  font-weight: 400;
}
.programGroup{
  font-size: 0.8rem !important;
  font-weight: 600;
}

.redHeader {
  font-size: 0.9rem !important;
  font-weight: 600;
  /* color: var(--darkRed); */
  color: black;
}
.awards {
  margin-left: 1%;
  font-size: 0.7rem !important;
  font-style: italic;
  padding-bottom: 50px;
}

.groupPic{
}

@media screen and (max-width: 992px) {
 .bioText{
  margin-left: 3%;
 }
  .groupPic {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .floatImg {
    float: none;
    justify-content: center;
    margin-right: 0rem;
  }

  .floatImgMonaCont{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .floatImgMona{
    float: left;
    margin-right: 0rem;
    margin-bottom: 5%;
  }

 
  /* .monaPic1{
    display: flex;
      flex-direction: row;
      justify-content: center;
  } */

  .groupName {
    text-align: center;
    margin-top: 2%;
    
  }
  .positionsGroup {

    padding-top: 0.5rem;
    padding-left:0 rem;
   
    /* width: fit-content; */
    /* border-bottom: solid var(--darkRed) 2px; */
  }
  .groupRowCont{

  }
}
